<template>
    <main class="o-main o-main--home" id="main">
        <div v-if="$mq !== 'xlarge' && $mq !== 'full'">
            <div v-if="!isLoading && homePage" class="o-container o-container--full o-layout--sm-only o-layout--gutter o-layout--multiline">
                <page-widget :id="`widget-${i}`" v-for="(widget, i) in homePage.page_builder"
                             :data="widget" :key="`${homePage.id}_${i}`"></page-widget>
            </div>
            <div class="o-container" v-else><span class="u-sr">Wird geladen...</span></div>
        </div>
        <div v-else>
            <div v-if="!isLoading && homePage" v-masonry="containerId" options="options" :gutter="20" :transition-duration="0" :is-animated="false" :init-layout="false" class="o-container o-container--full">
                <div class="o-layout__spacer"></div>
                <page-widget v-masonry-tile="containerId" :id="`widget-${i}`" v-for="(widget, i) in homePage.page_builder"
                             :data="widget" :key="`${homePage.id}_${i}`"></page-widget>
            </div>
            <div class="o-container" v-else><span class="u-sr">Wird geladen...</span></div>
        </div>
    </main>
</template>

<script>
    import api from '@/api'
    import pageWidget from '@/components/page/Widget.vue'
    import {mapGetters, mapActions} from 'vuex';

    export default {
        name: 'Home',

        watch: {
            'moreLoaded': function () {
                this.reDraw();
            }
        },

        data () {
            return {
                containerId: 'home',
                homePage: null,
                options: {
                    itemSelector: '.c-widget--page',
                    columnWidth: '.o-layout__spacer',
                    initLayout: false,
                    isAnimated: false,
                    transitionDuration: 0
                }
            }
        },

        beforeMount () {
            api.getPage(this.homepageID, page => {
                this.homePage = page

                let pagebuilder = [];
                this.homePage.page_builder.forEach((widget) => {
                  if(widget.acf_fc_layout == 'widget_recent_posts' || widget.acf_fc_layout == 'widget_recent_posts_manual') {
                    widget.posts.forEach((post) => {
                      pagebuilder.push({'acf_fc_layout': 'widget_article_preview', 'chosen_post': post, 'type': 'chosen'})
                    });
                  } else {
                    pagebuilder.push(widget);
                  }
                });

                this.homePage.page_builder = [{'acf_fc_layout': 'widget_top_story_slider', 'slides': this.homePage.topstories}].concat(pagebuilder);
                document.title = `${this.homePage.post_title} | Rheiner`
            })
        },

        mounted() {
            if (this.$mq !== 'small') this.$redrawVueMasonry('home');

            if (this.allPagesLoaded) {
                this.setCurrentMenu('home')
                this.openMenu(false)
            }

            document.title = 'Start | Rheiner'
        },

        methods: {
            ...mapActions({
                setCurrentMenu: 'setCurrentMenu',
                getPage: 'getPage',
                openMenu: 'openMenu',
            }),

            async reDraw(){
                await this.sleep(0)
                this.$redrawVueMasonry('home')
            },

            sleep(ms){
                return new Promise(resolve => setTimeout(resolve, ms))
            }
        },

        computed: {
            ...mapGetters({
                isLoading: 'isLoading',
                allPages: 'allPages',
                allPagesLoaded: 'allPagesLoaded',
                allPosts: 'allPosts',
                currentPost: 'currentPost',
                moreLoaded: 'moreLoaded',
            }),

            homepageID() {
                const findFrontPage = page => page['front_page'];
                const frontPage = this.allPages.find(findFrontPage);
                return frontPage.id
            }
        },

        components: {
            pageWidget
        }
    }
</script>