<template>
    <component class="c-widget c-widget--page" :class="{ 'o-layout__item o-layout__item--masonry' : !currPage  }" :is="component" :data="data" v-if="component"></component>
</template>

<script>
    import _camelCase from 'lodash.camelcase'

    export default {
        name: 'PostWidget',
        props: {
            data: Object,
            currPage: Object
        },

        data() {
            return {
                component: null
            }
        },

        mounted() {
            this.loader()
                .then(() => {
                    this.component = () => this.loader()
                })
                .catch(e => {
                    /*eslint no-console: ["error", { allow: ["error"] }] */
                    console.error(e)
                })

        },

        computed: {
            loader() {
                const upper = str => str.replace(/^\w/, c => c.toUpperCase());
                let component = this.data.acf_fc_layout.replace('widget_', '');
                component = _camelCase(component)
                component = upper(component)
                return () => import( /* webpackChunkName: "page_widget" */ `@/components/page/builder/${ component }`)
            }
        }
    }
</script>
